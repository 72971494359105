<template>
  <div class="sales-summary-chart">
    <bar-chart v-if="loaded" :chartdata="chartdata" :options="options" />
  </div>
</template>

<script>
import BarChart from "@/components/Charts/BarChart.vue";

export default {
  name: "SalesSummaryChart",
  components: { BarChart },
  data: () => ({
    loaded: false,
    chartdata: null,
    options: {
      maintainAspectRatio: false,
      responsive: true,
      title: {
        display: true,
        fontColor: "#ffffff",
        fontStyle: "normal",
        fontSize: 15,
        text: "Diagram Pendapatan Perbulan",
      },
      legend: {
        align: "start",
        display: false,
        labels: {
          fontColor: "#ffffff",
        },
        position: "bottom",
      },
      layout: {
        padding: {
          left: 10,
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              fontColor: "#ffffff",
              // stepSize: 2000,
              callback: function (value) {
                let decPlaces = Math.pow(10, 2);

                // Enumerate number abbreviations
                var abbrev = ["K", "M", "B", "T"];

                // Go through the array backwards, so we do the largest first
                for (var i = abbrev.length - 1; i >= 0; i--) {
                  // Convert array index to "1000", "1000000", etc
                  var size = Math.pow(10, (i + 1) * 3);

                  // If the number is bigger or equal do the abbreviation
                  if (size <= value) {
                    // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                    // This gives us nice rounding to a particular decimal place.
                    value = Math.round((value * decPlaces) / size) / decPlaces;

                    // Handle special case where we round up to the next abbreviation
                    if (value == 1000 && i < abbrev.length - 1) {
                      value = 1;
                      i++;
                    }

                    // Add the letter for the abbreviation
                    value += abbrev[i];

                    // We are done... stop
                    break;
                  }
                }

                return value;
              },
            },
            gridLines: {
              color: "rgba(255, 255, 255, 0.2)",
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              fontColor: "#ffffff",
            },
            gridLines: {
              color: "rgba(255, 255, 255, 0)",
            },
          },
        ],
      },
    },
  }),
  async mounted() {
    this.$http
      .get("/sales-summary-diagram")
      .then((result) => {
        const chartdata = {
          name: [],
          value: [],
        };

        result.data.forEach((element) => {
          chartdata.name.push(element.month);
          chartdata.value.push(element.value);
        });

        this.chartdata = {
          labels: chartdata.name,
          datasets: [
            {
              backgroundColor: [
                "rgba(255, 0, 0, 0.5)",
                "rgba(255, 153, 51, 0.5)",
                "rgba(255, 255, 0, 0.5)",
                "rgba(51, 204, 51, 0.5)",
                "rgba(0, 102, 255, 0.5)",
                "rgba(153, 102, 255, 0.5)",
                "rgba(204, 204, 204, 0.5)",
                "rgba(153, 102, 51, 0.5)",
                "rgba(0, 230, 184, 0.5)",
                "rgba(255, 0, 0, 0.5)",
                "rgba(255, 153, 51, 0.5)",
                "rgba(255, 255, 0, 0.5)",
              ],
              borderColor: [
                "rgba(255, 0, 0, 1)",
                "rgba(255, 153, 51, 1)",
                "rgba(255, 255, 0, 1)",
                "rgba(51, 204, 51, 1)",
                "rgba(0, 102, 255, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(204, 204, 204, 1)",
                "rgba(153, 102, 51, 1)",
                "rgba(0, 230, 184, 1)",
                "rgba(255, 0, 0, 1)",
                "rgba(255, 153, 51, 1)",
                "rgba(255, 255, 0, 1)",
              ],
              borderWidth: 1,
              data: chartdata.value,
              label: "Data",
            },
          ],
        };

        this.loaded = true;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>