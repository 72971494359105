<template>
  <div class="balance-sheets-chart">
    <pie-chart v-if="loaded" :chartdata="chartdata" :options="options" />
  </div>
</template>

<script>
import PieChart from "@/components/Charts/PieChart.vue";

export default {
  name: "BalanceSheetsChart",
  components: { PieChart },
  data: () => ({
    loaded: false,
    chartdata: null,
    options: {
      maintainAspectRatio: false,
      responsive: true,
      title: {
        display: true,
        fontColor: "#ffffff",
        fontFamily: "'Poppins', sans-serif",
        fontStyle: "normal",
        fontSize: 15,
        text: "Diagram Laba Berjalan",
      },
      legend: {
        align: "start",
        labels: {
          fontColor: "#ffffff",
          fontFamily: "'Poppins', sans-serif",
        },
        position: "bottom",
      },
    },
  }),
  async mounted() {
    this.$http
      .get("/balance-sheet-diagram")
      .then((result) => {
        const chartdata = {
          name: [],
          value: [],
        };

        result.data.forEach((element) => {
          chartdata.name.push(element.name);
          chartdata.value.push(element.value);
        });

        this.chartdata = {
          labels: chartdata.name,
          datasets: [
            {
              backgroundColor: [
                "#84c3ec",
                "#2a323d",
                "#aad15c",
                "#f5ac2c",
                "#7b83c2",
              ],
              borderColor: "#282b30",
              borderWidth: 1,
              data: chartdata.value,
              label: "Data",
            },
          ],
        };

        this.loaded = true;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>