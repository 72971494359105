<template>
  <v-card :loading="loading" class="bank-balances-card mx-auto">
    <template slot="progress">
      <v-progress-linear color="light-blue" indeterminate></v-progress-linear>
    </template>

    <v-card-title>Saldo Kas/Bank</v-card-title>

    <v-divider></v-divider>

    <v-card-text v-if="failed">
      <v-alert class="mb-0" type="error" dense outlined>
        Gagal mendapatkan data
      </v-alert>
    </v-card-text>

    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr v-for="d in data" :key="d.id">
              <td class="text-left" v-text="d.bank_account_name"></td>
              <td class="text-right" v-text="formatPrice(d.balance)"></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <!-- <v-list v-else>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="d in data"
          :key="d.id"
          :to="'/bank/' + d.id + '/balance/details'"
        >
          <v-list-item-content>
            <v-list-item-title v-text="d.bank_account_name"></v-list-item-title>
            <v-list-item-subtitle
              v-text="formatPrice(d.balance)"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list> -->
  </v-card>
</template>

<script>
export default {
  name: "BankBalancesCard",
  data: () => ({
    data: [],
    failed: false,
    loading: false,
  }),
  async mounted() {
    this.loading = true;

    this.$http
      .get("/bank-balance")
      .then((result) => {
        this.data = result.data;
        this.loading = false;
      })
      .catch((error) => {
        this.failed = true;
        this.loading = false;
        console.log(error);
      });
  },
  methods: {
    formatPrice(value) {
      if (value == "0") return value;
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>