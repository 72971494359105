<template>
  <div class="dashboard">
    <sales-summary-chart class="mb-5" />

    <balance-sheets-chart class="mb-5" />

    <detailed-balance-sheets class="mb-5"></detailed-balance-sheets>

    <bank-balances-card />
  </div>
</template>

<script>
import SalesSummaryChart from "@/components/Charts/SalesSummaryChart.vue";
import BalanceSheetsChart from "@/components/Charts/BalanceSheetsChart.vue";
import DetailedBalanceSheets from "@/components/DetailedBalanceSheets.vue";
import BankBalancesCard from "../components/BankBalancesCard.vue";

export default {
  name: "Dashboard",

  components: {
    SalesSummaryChart,
    BalanceSheetsChart,
    DetailedBalanceSheets,
    BankBalancesCard,
  },
};
</script>

<style lang="scss">
.sales-summary-chart {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  width: 90vw;
}

.balance-sheets-chart {
  margin-left: auto;
  margin-right: auto;
  width: 60vw;
}

.detailed-balance-sheets-card {
  margin-left: auto;
  margin-right: auto;
  width: 90vw;
}

.bank-balances-card {
  width: 90vw;
}
</style>