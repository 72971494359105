<template>
  <v-card class="detailed-balance-sheets-card">
    <v-card-title> Ringkasan Neraca </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <template v-for="accountClass in accountClasses">
              <tr class="header" :key="'h-' + accountClass.id">
                <th
                  class="text-center"
                  colspan="2"
                  v-text="accountClass.name"
                ></th>
              </tr>
              <tr
                v-for="accountType in accountClass.account_types"
                :key="accountType.id"
              >
                <td class="text-left" v-text="accountType.name"></td>
                <td class="text-right" v-text="accountType.total"></td>
              </tr>
              <tr class="footer" :key="'f-' + accountClass.id">
                <td class="text-left">Total</td>
                <td class="text-right">{{ accountClass.total }}</td>
              </tr>
            </template>
            <tr class="footer">
              <td class="text-left">Net Profit</td>
              <td class="text-right" v-text="total"></td>
            </tr>
            <tr class="footer">
              <td class="text-left">Total Hutang dan Ekuitas</td>
              <td class="text-right" v-text="leTotal"></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DetailedBalanceSheets",

  data: () => ({
    accountClasses: [],

    leTotal: 0,

    loading: false,

    total: 0,
  }),

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;

      this.$http.get("/balance-sheet/detailed").then((result) => {
        this.accountClasses = result.data.data;
        this.total = result.data.total;
        this.leTotal = result.data.le_total;

        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header th {
  background-color: #333333;
}

.footer td {
  background-color: #262626;
}
</style>